import * as types from './Auth.actionTypes';

export const loginFlow = {
  request: params => ({
    type: types.LOGIN_REQUEST,
    params,
  }),
  success: data => ({
    type: types.LOGIN_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.LOGIN_FAILURE,
    payload: err,
  }),
};

export const login = loginFlow.request;

export const sendLoginVerificationCodeFlow = {
  request: ({ params, callback }) => ({
    type: types.SEND_LOGIN_VERIFICATION_CODE_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.SEND_LOGIN_VERIFICATION_CODE_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.SEND_LOGIN_VERIFICATION_CODE_FAILURE,
    payload: err,
  }),
};

export const sendLoginVerificationCode = sendLoginVerificationCodeFlow.request;

export const changeLoginStep = ({ stepIndex }) => ({
  type: types.UPDATE_LOGIN_STEP,
  payload: {
    stepIndex,
  },
});

export const updateLoginDetails = params => ({
  type: types.UPDATE_LOGIN_FORM_DETAILS,
  payload: {
    data: params,
  },
});

export const getUserDetailsFlow = {
  request: params => ({
    type: types.FETCH_USER_DETAILS_REQUEST,
    params,
  }),
  success: data => ({
    type: types.FETCH_USER_DETAILS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_USER_DETAILS_FAILURE,
    payload: err,
  }),
};

export const getUserDetails = getUserDetailsFlow.request;

export const editUserDetails = payload => ({
  type: types.EDIT_USER_DETAILS,
  payload,
});

export const updateUserDetailsFlow = {
  request: action => ({
    type: types.UPDATE_USER_DETAILS_REQUEST,
    ...action,
  }),
  success: (data, meta) => ({
    type: types.UPDATE_USER_DETAILS_SUCCESS,
    payload: data,
    meta,
  }),
  failure: err => ({
    type: types.UPDATE_USER_DETAILS_FAILURE,
    payload: err,
  }),
};

export const updateUserDetails = updateUserDetailsFlow.request;

export const updateUserPassesFlow = {
  request: action => ({
    type: types.UPDATE_USER_PASSES_LINK_REQUEST,
    ...action,
  }),
  success: data => ({
    type: types.UPDATE_USER_PASSES_LINK_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.UPDATE_USER_PASSES_LINK_FAILURE,
    payload: err,
  }),
};

export const updateUserPasses = updateUserPassesFlow.request;
