import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SearchInput } from 'components/common';

const InputWrapper = styled.div`
  position: relative;
  top: 2rem;
  z-index: 1;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
`;

function SearchField({ onChange }) {
  return (
    <InputWrapper>
      <SearchInput
        onChange={onChange}
        size="md"
        iconAlignment="right"
        placeholder="Enter Zip code, Location, Name"
      />
    </InputWrapper>
  );
}

SearchField.propTypes = {
  onChange: PropTypes.func,
};

SearchField.defaultProps = {
  onChange: () => ({}),
};

export default SearchField;
