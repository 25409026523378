import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { Button, Card } from 'components/common';

const StyledMerchant = styled(Card)`
  padding: 26px;

  .brand-wrapper {
    width: 48px;
    height: 48px;
    border: 0.447205px solid rgba(52, 67, 86, 0.2);
  }
`;

const StyledMerchantName = styled.div`
  color: ${props => props.theme.colorText};

  & > p {
    margin-bottom: 0;
  }
`;

const StoreOverview = ({ history, store }) => {
  if (isEmpty(store)) {
    return null;
  }
  const { merchantID } = store;
  return (
    <StyledMerchant
      white
      onClick={() =>
        history.push(
          `/merchants/${merchantID.merchantID}/stores/${store.storeID}`
        )
      }
    >
      <div className="flex justify-content-space-between">
        <div className="brand-wrapper text-center">
          <img src={store.logo} alt={store.name} />
        </div>
        <StyledMerchantName className="flex flex--column flex-1">
          <h5>{store.name}</h5>
          <p>Medical - 3 miles</p>
        </StyledMerchantName>
        <img src="/assets/images/icons/green-star.svg" alt="" />
      </div>
      <hr />
      <div className="flex justify-content-space-between align-items-center">
        <div>
          <h5>Free $10 Voucher</h5>
          <div className="flex">
            <img src="/assets/images/icons/points-orange.svg" alt="" />
            <p className="pl-2">
              <span>1234</span>
              &nbsp;points
            </p>
          </div>
        </div>
        <Button size="small" primary>
          Redeem
        </Button>
      </div>
    </StyledMerchant>
  );
};

StoreOverview.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object,
};

StoreOverview.defaultProps = {
  store: null,
};

export default withRouter(StoreOverview);
