import React from 'react';
import styled from 'styled-components';

const StyledProgress = styled.div`
  position: relative;
  display: flex;

  &:after,
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    transform: translateY(-50%);
    z-index: 1;
  }

  &:before {
    background-color: ${props => props.theme.colorProgressBar};
    opacity: 0.1;
    width: 97%;
  }

  &:after {
    width: ${props => props.percentComplete}%;
    background: ${props => props.theme.colorActiveStep};
    opacity: 1;
  }

  .progress-step {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: left;
    z-index: 10;

    &:last-child {
      flex-shrink: 2;
    }

    // Hide the final step's progress bar
    &:last-child:after {
      display: none;
    }

    // Step's circle in default state
    &:before {
      content: '';
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      margin-bottom: 10px;
      width: 16px;
      height: 16px;
      background: ${props => props.theme.colorNextStep};
      border-radius: 100%;
    }
  }

  & .is-complete {
    // Step's circle in complete state
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: ${props => props.theme.colorActiveStep};
      z-index: 2;
    }
  }

  & .is-active {
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: url('/assets/images/icons/points-orange.svg');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
    }
  }
`;

export default () => {
  const percentComplete = (1300 / 3000) * 100;
  return (
    <StyledProgress className="progress" percentComplete={percentComplete}>
      <div id="step1" className="progress-step is-complete">
        0
      </div>

      <div id="step2" className="progress-step is-complete">
        1000
      </div>

      <div id="step3" className="progress-step is-active">
        2000
      </div>

      <div id="step4" className="progress-step">
        3000
      </div>
    </StyledProgress>
  );
};
