import { createAsyncActions } from 'utils/createAsyncActions';

const [detailRequest, detailSuccess, detailFailure] = createAsyncActions(
  'FETCH_STORES_DETAILS'
);
const [nearByRequest, nearBySuccess, nearByFailure] = createAsyncActions(
  'FETCH_STORES_NEARBY'
);

const [productRequest, productSuccess, productFailure] = createAsyncActions(
  'FETCH_STORE_PRODUCTS'
);

export const storeDetailFlow = {
  request: detailRequest,
  success: detailSuccess,
  failure: detailFailure,
};

export const storeNearByFlow = {
  request: nearByRequest,
  success: nearBySuccess,
  failure: nearByFailure,
};

export const storeProductsFlow = {
  request: productRequest,
  success: productSuccess,
  failure: productFailure,
};
