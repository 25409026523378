import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// HELPERS
import { usePrevious } from 'utils/hooks';

// ACTIONS
import { getUserDetails } from 'redux/Auth/Auth.actions';

// SELECTORS
import { createLoadingSelector } from 'redux/ApiStatus/ApiStatus.selectors';
import { userDetailsSelector } from 'redux/Auth/Auth.selectors';

// ICON
import Loading from 'components/Loading/Loading';

const loadingSelector = createLoadingSelector(['FETCH_USER_DETAILS']);

const AuthenticatedRoute = ({ children, history }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const prevLoading = usePrevious(loading);
  const userDetails = useSelector(userDetailsSelector());

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    if (prevLoading && !loading && !userDetails) {
      history.push('/login');
    }
  }, [loading, userDetails]);

  if (loading) return <Loading type="dots" isScreen />;
  if (!userDetails) return null;
  return children({ userDetail: userDetails });
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.func,
  ]),
};
export default withRouter(AuthenticatedRoute);
