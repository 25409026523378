import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WiTime3 } from 'react-icons/wi';

import Coupon from 'components/Coupon/Coupon';
import CouponRow from 'components/Coupon/CouponRow';

import { storeProductsFlow } from 'redux/Stores/Stores.actions';
import { storeProductsSelector } from 'redux/Stores/Stores.selectors';

const StyledHeadline = styled.h3`
  font-size: 17px;
  color: ${props => props.theme.colorText};
  line-height: 26px;
  margin-bottom: 8px;
`;

const contentTemplate = expiringDays => (
  <span className="flex align-items-center" style={{ fontSize: '13px' }}>
    <WiTime3 className="mr-2" style={{ fontSize: '16px' }} />
    Expring in &nbsp;
    {expiringDays}
    &nbsp; days
  </span>
);

function StoreDeals() {
  const dispatch = useDispatch();
  let { merchantId, id: storeId } = useParams();

  React.useEffect(() => {
    dispatch(storeProductsFlow.request({ merchantId, storeId }));
  }, []);

  const coupons = useSelector(storeProductsSelector());

  if (!coupons.length) {
    return null;
  }

  return (
    <section id="daily-deal" className="mb-6">
      <StyledHeadline>Deals</StyledHeadline>
      <CouponRow className="mt-3">
        {coupons.map(({ productID: product }) => (
          <Coupon
            key={product.productID}
            title={`$${product.price} ${product.name}`}
            content={contentTemplate(3)}
          />
        ))}
      </CouponRow>
    </section>
  );
}

export default StoreDeals;
