import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const AuthenticationService = createService(Apis.authentication, {
  sendLoginVerificationCode: {
    method: 'POST',
    url: `${Apis.authentication}/login/`,
    isSafe: true,
  },
  login: {
    method: 'POST',
    url: `${Apis.authentication}/login/verify/otp`,
  },
  createUserDetails: {
    method: 'POST',
    url: `${Apis.authentication}/signup`,
    isSafe: true,
  },
  sendVerifyCode: {
    method: 'POST',
    url: `${Apis.authentication}/signup/phone/verification`,
    isSafe: true,
  },
  verifyPhoneNumber: {
    method: 'PUT',
    url: `${Apis.authentication}/signup/phone/verification`,
  },
  linkBankAccount: {
    method: 'PUT',
    url: `${Apis.authentication}/signup/bankItems/add`,
  },
  setPinCode: {
    method: 'PUT',
    url: `${Apis.users}/pin`,
  },
  getUserDetails: {
    method: 'GET',
    url: `${Apis.users}/me`,
  },
  updateUserDetails: {
    method: 'PUT',
    url: `${Apis.users}/me`,
  },
});

export default AuthenticationService;
