import { call, put, takeLatest } from 'redux-saga/effects';

// HELPERS
import { doSagaActionGenerator } from 'utils/sagaHelpers';

// SERVICES
import AuthenticationService from 'services/Auth.service';
import UserServices from 'services/User.service';

// HELPERS
import { toSavingUSPhoneNumber } from 'utils/number';

// ACTION TYPES
import * as types from './Auth.actionTypes';

// FLOW
import {
  loginFlow,
  getUserDetailsFlow,
  updateUserDetailsFlow,
  sendLoginVerificationCodeFlow,
  updateUserPassesFlow,
} from './Auth.actions';

export function* sendLoginVerificationCode() {
  yield takeLatest(types.SEND_LOGIN_VERIFICATION_CODE_REQUEST, action =>
    doSagaActionGenerator({
      action: {
        ...action,
        params: {
          ...action.params,
          phoneNumber: toSavingUSPhoneNumber(action.params.phoneNumber),
        },
      },
      flow: sendLoginVerificationCodeFlow,
      apiService: AuthenticationService.sendLoginVerificationCode,
    })
  );
}

function* doLogin(action) {
  const {
    params: { pin },
  } = action;
  const response = yield call(AuthenticationService.login, null, {
    loginType: 'PHONE',
    code: pin.join(''),
  });
  if (response.error) {
    yield put(loginFlow.failure(response));
    return;
  }
  const { data } = response;
  if (!data) {
    yield put(loginFlow.failure(null));
    return;
  }
  yield put(loginFlow.success(data));
}

export function* login() {
  yield takeLatest(types.LOGIN_REQUEST, doLogin);
}

export function* getUserDetails() {
  yield takeLatest(types.FETCH_USER_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: getUserDetailsFlow,
      apiService: AuthenticationService.getUserDetails,
    })
  );
}

export function* updateUserDetails() {
  yield takeLatest(types.UPDATE_USER_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: updateUserDetailsFlow,
      apiService: AuthenticationService.updateUserDetails,
    })
  );
}

export function* updateUserPasses() {
  yield takeLatest(types.UPDATE_USER_PASSES_LINK_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: updateUserPassesFlow,
      apiService: UserServices.createPasses,
    })
  );
}

// // FLOW:
// flow: {
//   request: {
//     type: 'TYPE_REQUEST',
//     cb: () => {},
//   },
//   failure: {
//     type: 'TYPE_FAILURE',
//     cb: () => {},
//   },
//   success: {
//     type: 'TYPE_UCCESS',
//     cb: () => {},
//   }
// }
