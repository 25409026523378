import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft } from 'react-icons/fi';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { storeDetailFlow } from 'redux/Stores/Stores.actions';
import { createLoadingSelector } from 'redux/ApiStatus/ApiStatus.selectors';
import { storeDetailSelector } from 'redux/Stores/Stores.selectors';

import Navigation from 'components/Navigation/Navigation';
import { Tabs, Loading } from 'components/common';
import StoreRewardsTab from 'components/StoreDetails/RewardsTab';
import StoreLocations from 'components/StoreDetails/LocationTab';
import StoreDetailTab from 'components/StoreDetails/DetailTab';

const StyledPoints = styled.div`
  background: rgba(255, 187, 16, 0.1);
  color: ${props => props.theme.colorOrange};
  height: 34px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-size: 17px;
`;
const BrandPoints = ({ points }) => {
  return (
    <StyledPoints>
      <img src="/assets/images/icons/points-orange.svg" alt="point" />
      &nbsp;
      {points}
    </StyledPoints>
  );
};

BrandPoints.propTypes = {
  points: PropTypes.number,
};

BrandPoints.defaultProps = {
  points: 0,
};

const StoreWrapper = styled.div`
  height: 186px;
  position: relative;
  top: -24px;
  background-image: url(${props => props.bg});
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;

  h4 {
    font-size: 17px;
  }
  p {
    margin-bottom: 0;
  }

  & > div {
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-white {
    color: #fff;
  }
`;

const StyledBrandImage = styled.div`
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 8px;
  background: #fff;
  border-radius: 8px;
  img {
    width: 59px;
  }
`;

const tabs = [
  {
    key: 'detail',
    label: 'DETAIL',
    component: <StoreDetailTab />,
  },
  {
    key: 'reward',
    label: 'REWARD',
    component: <StoreRewardsTab />,
  },
  {
    key: 'locations',
    label: 'LOCATIONS',
    component: <StoreLocations />,
  },
];

const loadingSelector = createLoadingSelector(['FETCH_STORES_DETAILS']);

function StoreDetail({ history, match }) {
  const { merchantId, id: storeId } = match.params;
  const dispatch = useDispatch();

  const isLoading = useSelector(loadingSelector);
  const storeDetail = useSelector(storeDetailSelector());

  React.useEffect(() => {
    dispatch(storeDetailFlow.request({ merchantId, storeId }));
  }, []);

  if (isLoading || isEmpty(storeDetail)) {
    return <Loading />;
  }

  const { store } = storeDetail;

  return (
    <div className="mb-5">
      <Navigation
        leftElem={<FiChevronLeft onClick={() => history.push('/merchants')} />}
        label={<strong>{store.name}</strong>}
        rightElem={<BrandPoints points={2345} />}
      />
      <StoreWrapper bg={store.banner}>
        <div className="flex align-items-center">
          <StyledBrandImage className="flex align-items-center justify-content-center">
            <img src={store.logo} />
          </StyledBrandImage>
          <div className="flex-1 ml-3 text-white">
            <h4 className="text-white">{`${store.name} ${store.locationName}`}</h4>
            <p className="mt-0">{store.address}</p>
          </div>
        </div>
      </StoreWrapper>
      <Tabs tabs={tabs} />
    </div>
  );
}

StoreDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default React.memo(StoreDetail);
