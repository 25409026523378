import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCouponsWrapper = styled.div`
  display: flex;
  margin-right: -30px;
  padding-right: 30px;
  overflow: auto;
  & > div {
    width: 65vw;
    min-width: 200px;
    margin-right: 24px;
  }
`;

const CouponRow = ({ children, className }) => {
  return (
    <StyledCouponsWrapper className={className}>
      {children}
    </StyledCouponsWrapper>
  );
};

CouponRow.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
};
CouponRow.defaultProps = {
  children: null,
  className: null,
};

export default CouponRow;
