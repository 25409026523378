import React from 'react';
import PropTypes from 'prop-types';
import { Map as GoogleMap, GoogleApiWrapper } from 'google-maps-react';

const defaultMapProps = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControl: false,
  zoom: 12,
};

const Map = ({ children, loaded, google, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <GoogleMap {...defaultMapProps} google={google} {...rest}>
    {children}
  </GoogleMap>
);

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  loaded: PropTypes.bool,
  google: PropTypes.object.isRequired,
};
Map.defaultProps = {
  children: null,
  loaded: false,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
})(Map);
