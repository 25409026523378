import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/common';
import { Link } from 'react-router-dom';

const StyledProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    left: ${props => (props.size === 'small' ? '8px' : '19px')};
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: ${props => props.theme.colorProgressBar};
    opacity: 0.1;
    z-index: 1;
  }

  .progress-step {
    line-height: 1;
    width: 100%;
    text-align: left;
    z-index: 10;
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    // Step's circle in default state
    .progress-icon {
      display: flex;
      font-size: 15px;
      font-weight: bold;
      color: ${props => props.theme.colorActiveStep};
      width: ${props => (props.size === 'small' ? '16px' : '38px')};
      height: ${props => (props.size === 'small' ? '16px' : '38px')};
      background: url('${props => props.stepBackground}');
      background-repeat: no-repeat;
      background-position: cover;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }
  }

  & .is-complete {
    // Step's circle in complete state
    .progress-icon {
      color: transparent;
      background: url('${props => props.completeBackground}');
    }
  }
`;

const StyledSmall = styled.small`
  line-height: 13px;
  font-size: 11px;
`;

const StyledH5 = styled.h5`
  font-weight: bold;
  line-height: 160%;
`;

export default () => {
  return (
    <StyledProgress
      vertical
      className="progress"
      completeBackground="/assets/images/icons/active-octagon.svg"
      stepBackground="/assets/images/icons/octagon.svg"
    >
      <div id="step1" className="progress-step is-complete">
        <div className="progress-icon">1</div>
        <div className="flex--1 ml-5 mb-5">
          <StyledSmall>Collect 1000 Points </StyledSmall>
          <StyledH5 className="mt-1">Free Pre Roll Joint</StyledH5>
        </div>
      </div>

      <div id="step2" className="progress-step">
        <div className="progress-icon">2</div>
        <div className="flex--1 ml-5 mb-5">
          <StyledSmall>Collect 2000 Points </StyledSmall>
          <StyledH5 className="mt-1">Free $50 Voucher</StyledH5>
          <div className="mt-2">
            <Link to="/merchants/stores/33/reward/1">
              <Button size="small" secondary>
                Earn
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div id="step3" className="progress-step">
        <div className="progress-icon">3</div>
        <div className="flex--1 ml-5">
          <StyledSmall>Collect 3000 Points </StyledSmall>
          <StyledH5 className="mt-1">Free $100 Voucher</StyledH5>
        </div>
      </div>
    </StyledProgress>
  );
};
