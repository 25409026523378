import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledReward = styled.div`
  width: calc(50% - 8px);
  margin-bottom: 30px;
  .reward__thumbnail {
    margin-bottom: 8px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
    }
  }
  .reward__source-logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 32px;
    }
  }
  .reward__source-name {
    font-size: 13px;
    letter-spacing: -0.08px;
  }

  b {
    color: ${props => props.theme.colorOrange};
    font-size: 13px;
  }
`;
const Reward = ({ reward }) => {
  if (!reward) return null;
  const { thumbnail, name, source, points } = reward;
  return (
    <StyledReward className="reward">
      <div className="reward__thumbnail">
        <img alt={name} src={thumbnail} />
        <div className="reward__source-logo">
          <img alt={source.name} src={source.logo} />
        </div>
      </div>
      <div className="flex flex--column">
        <span className="reward__source-name">{source.name}</span>
        <h5>{name}</h5>
        <span className="flex align-items-center">
          <b className="flex align-items-center">
            <img src="/assets/images/icons/points-orange.svg" />
            &nbsp;
            {points}
          </b>
          &nbsp;
          <span style={{ fontSize: '11px' }}>points</span>
        </span>
      </div>
    </StyledReward>
  );
};

Reward.propTypes = {
  reward: PropTypes.object,
};
Reward.defaultProps = {
  reward: null,
};

export default Reward;
