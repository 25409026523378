import { handleActions } from 'redux-actions';
import {
  storeDetailFlow,
  storeNearByFlow,
  storeProductsFlow,
} from 'redux/Stores/Stores.actions';
import { initialState } from './Stores.initialState';

const storeReducer = handleActions(
  {
    [storeDetailFlow.success]: (state, action) => ({
      ...state,
      store: action.payload,
    }),
    [storeNearByFlow.success]: (state, action) => ({
      ...state,
      storesNearBy: action.payload,
    }),
    [storeProductsFlow.success]: (state, action) => ({
      ...state,
      storeProducts: action.payload,
    }),
  },
  initialState
);

export default storeReducer;
