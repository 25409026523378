import { startsWith } from 'lodash';

export const toCurrency = (val, currency = '$') => `${currency}${Number(val)}`;

export const toUSPhoneNumber = input => {
  if (!input) return '';
  if (startsWith(input, '+')) return input;
  let output = '+1 (';
  input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function(
    _match,
    g1,
    g2,
    g3
  ) {
    if (g1.length) {
      output += g1;
      if (g1.length === 3) {
        output += ')';
        if (g2.length) {
          output += ` ${g2}`;
          if (g2.length === 3) {
            output += ' - ';
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  });
  return output;
};

export const toSavingUSPhoneNumber = input => {
  if (startsWith(input, '+')) return input;
  return `+1${input}`;
};

export const toBankAccountNumber = input => input.replace(/\d(?=\d{4})/g, '•');
