import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { DAY_OF_WEEK_FORMAT } from 'constants/index';

const StyledOpenHours = styled.div`
 & > div {
    flex-basic: 45%;
    color: ${props => props.theme.colorText}
    font-size: 15px;
    font-weight: 600;
  }

  .open-day {
    opacity: 0.8;
    font-weight: normal;
  }
`;

const StyledHeadline = styled.h3`
  font-size: 17px;
  color: ${props => props.theme.colorText};
  line-height: 26px;
  margin-bottom: 8px;
`;

function StoreOpenHour({ openDays = [] }) {
  const element = openDays
    .sort((first, second) => (first.dayInWeek > second.dayInWeek ? 1 : -1))
    .map(({ dayInWeek, openTime, closeTime }) => (
      <div className="pt-3 pb-3">
        <span className="open-day">
          {moment()
            .day(dayInWeek)
            .format(DAY_OF_WEEK_FORMAT)}
        </span>
        <span className="pl-2 open-hour">{`${openTime} - ${closeTime} PM`}</span>
      </div>
    ));

  return (
    <section id="working-hour">
      <StyledHeadline>Working Hours</StyledHeadline>
      <StyledOpenHours className="flex flex--wrap justify-content-space-between">
        {element}
      </StyledOpenHours>
    </section>
  );
}

StoreOpenHour.propsTypes = {
  openDays: PropTypes.arrayOf(
    PropTypes.shape({
      dayInWeek: PropTypes.number.isRequired,
      openTime: PropTypes.string.isRequired,
      closeTime: PropTypes.string.isRequired,
    })
  ),
};

StoreOpenHour.defaultProps = {
  openDays: [],
};

export default StoreOpenHour;
