import React from 'react';
import { Marker } from 'google-maps-react';

export default function MarkerContainer(props) {
  const icon = props.active
    ? '/assets/images/icons/marker-active.svg'
    : '/assets/images/icons/marker.svg';

  return <Marker icon={icon} {...props} />;
}
