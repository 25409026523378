import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import Map from 'components/Map/Map';
import MapMarker from 'components/Map/MarkerContainer';
import SearchField from 'components/Merchants/SearchField';
import StoreOverview from 'components/Merchants/StoreOverview';
import Navigation from 'components/Navigation/Navigation';

import { storeNearByFlow, storeDetailFlow } from 'redux/Stores/Stores.actions';
import { storesNearBySelector } from 'redux/Stores/Stores.selectors';

import useGeocoding from 'hooks/useGeocoding';

const StoreContainer = styled.div`
  position: relative;
  top: -24px;
  height: 100vh;

  & > #merchant-wrapper {
    position: absolute;
    bottom: 100px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

function StoreBrand() {
  return <img src="/assets/images/logos/cannabux.svg" />;
}

const StoreMap = React.memo(
  ({ query, onSelectStore = () => ({}), selectedStore }) => {
    const dispatch = useDispatch();
    const stores = useSelector(storesNearBySelector());
    const { getGeoCoder, geoCoder } = useGeocoding(query);

    React.useEffect(() => {
      const { location } = geoCoder.geometry;
      const params = {
        latitude: location.lat,
        longitude: location.lng,
      };
      if (location.lat && location.lng) {
        dispatch(storeNearByFlow.request(params));
      }
    }, [geoCoder]);

    const mapMarkerHandler = useCallback(
      props => onSelectStore(props.store),
      []
    );

    const onMapReady = useCallback(getGeoCoder, []);
    const { location } = geoCoder.geometry;

    return (
      <Map
        center={{ lat: location.lat, lng: location.lng }}
        onReady={onMapReady}
      >
        {stores.map(store => (
          <MapMarker
            key={store.storeID}
            active={selectedStore && store.storeID === selectedStore.storeID}
            store={store}
            position={{ lat: store.lat, lng: store.lng }}
            onClick={mapMarkerHandler}
          />
        ))}
      </Map>
    );
  }
);

function StoreExplore({ history }) {
  const [query, setQuery] = useState('Chicago');
  const [selectedStore, setSelectedStore] = useState(null);

  return (
    <div>
      <Navigation
        leftElem={<FiChevronLeft onClick={() => history.push('/')} />}
        label={<StoreBrand />}
      />
      <StoreContainer>
        <StoreMap
          query={query}
          onSelectStore={setSelectedStore}
          selectedStore={selectedStore}
        />
        <SearchField
          onChange={debounce(query => {
            setSelectedStore(null);
            setQuery(query);
          }, 1000)}
        />
        <div id="merchant-wrapper">
          <StoreOverview store={selectedStore} />
        </div>
      </StoreContainer>
    </div>
  );
}

StoreExplore.propTypes = {
  history: PropTypes.object.isRequired,
};

export default StoreExplore;
