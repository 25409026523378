import { combineReducers } from 'redux';

// REDUCERS
import bankAccount from './BankAccount/BankAccount.reducer';
import auth from './Auth/Auth.reducer';
import register from './Register/Register.reducer';
import globalUI from './GlobalUI/GlobalUI.reducer';
import reward from './Reward/Reward.reducer';
import stores from './Stores/Stores.reducer';
import { apiStatus } from './ApiStatus/ApiStatus.reducer';
// import reservationSettings from './FormSubmissions/formSubmissions.reducer';

const rootReducer = combineReducers({
  bankAccount,
  auth,
  register,
  globalUI,
  reward,
  apiStatus,
  stores,
});

export default rootReducer;
