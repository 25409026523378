import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledTitles = styled.div`
  span {
    font-size: 13px;
    letter-spacing: 0.76px;
    transition: 0.4s all;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 30px;
      width: 1px;
      background-color: #c4c4c4;
      opacity: 0.21;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &.active {
      font-weight: bold;
      color: ${props => props.theme.colorPrimary};
    }
  }
`;
const Tabs = ({ tabs, defaultActive, isLinkable }) => {
  const [activeTabKey, setActiveTab] = useState(null);

  useEffect(() => {
    if (tabs && tabs.length) {
      setActiveTab(defaultActive || tabs[0].key);
    }
  }, []);
  const activeTab = tabs.find(item => item.key === activeTabKey);
  const onTabChange = tab => () => setActiveTab(tab.key);

  return (
    <div className="tabs">
      <StyledTitles className="tabs-title flex">
        {tabs.map(tab => (
          <span
            className={classnames(
              'flex flex--1 align-items-center justify-content-center',
              { active: !isLinkable && activeTabKey === tab.key }
            )}
            key={tab.key}
            onClick={onTabChange(tab)}
          >
            {tab.label}
          </span>
        ))}
      </StyledTitles>
      <hr className="mt-3 mb-3" />
      <div className="tabs-content">
        {activeTab && activeTab.component ? activeTab.component : null}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  defaultActive: PropTypes.string,
  isLinkable: PropTypes.bool,
};

Tabs.defaultProps = {
  defaultActive: null,
  isLinkable: false,
};

export default Tabs;
