import { map } from 'lodash';
import { all, fork } from 'redux-saga/effects';

import * as authSagas from './Auth/Auth.sagas';
import * as registerSagas from './Register/Register.sagas';
import * as storesSagas from './Stores/Stores.sagas';
import * as userSagas from './BankAccount/BankAccount.sagas';

export default function* rootSaga() {
  yield all(map(authSagas, item => fork(item)));
  yield all(map(registerSagas, item => fork(item)));
  yield all(map(storesSagas, item => fork(item)));
  yield all(map(userSagas, item => fork(item)));
}
