import { call, put, takeLatest } from 'redux-saga/effects';

import { doSagaActionGenerator } from 'utils/sagaHelpers';

// SERVICES
import MerchantsService from 'services/Merchants.service';

// FLOW
import {
  storeDetailFlow,
  storeNearByFlow,
  storeProductsFlow,
} from './Stores.actions';
import StoreService from 'services/Stores.service';

// sagas handlers
function* getStoreDetail(action) {
  const {
    payload: { merchantId, storeId },
  } = action;
  const response = yield call(MerchantsService(merchantId, storeId).getStore);
  if (response.error) {
    yield put(storeDetailFlow.failure(response));
    return;
  }
  const { data } = response;
  if (!data) {
    yield put(storeDetailFlow.failure(null));
    return;
  }
  yield put(storeDetailFlow.success(data));
}

function* getStoreNearBy(action) {
  const response = yield call(StoreService.getNearbyStores, action.payload);
  if (response.error) {
    yield put(storeNearByFlow.failure(response));
    return;
  }
  const { data } = response;
  if (!data) {
    yield put(storeNearByFlow.failure(null));
    return;
  }
  yield put(storeNearByFlow.success(data.list));
}

// sagas watching
export function* storeDetail() {
  yield takeLatest([storeDetailFlow.request], getStoreDetail);
}

export function* storeNearBy() {
  yield takeLatest([storeNearByFlow.request], getStoreNearBy);
}

export function* storeProducts() {
  yield takeLatest(storeProductsFlow.request, action => {
    const {
      payload: { merchantId, storeId },
    } = action;
    return doSagaActionGenerator({
      action: {
        ...action,
        queryParams: {},
        params: {},
      },
      flow: storeProductsFlow,
      apiService: MerchantsService(merchantId, storeId).getStoreProducts,
    });
  });
}

