import { takeLatest } from 'redux-saga/effects';

// SERVICES
import UserService from 'services/User.service';

// HELPERS
import { doSagaActionGenerator } from 'utils/sagaHelpers';

// ACTION TYPES
import * as types from './BankAccount.actionTypes';

// FLOWS
import {
  fetchBankAccountsFlow,
  fetchBankAccountDetailsFlow,
  setDefaultBankAccountFlow,
  addBankAccountFlow,
  removeBankAccountFlow,
} from './BankAccount.actions';

export function* fetchBankAccounts() {
  yield takeLatest(types.FETCH_BANK_ACCOUNTS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchBankAccountsFlow,
      apiService: UserService.fetchBankAccounts,
    })
  );
}

export function* fetchBankAccountDetails() {
  yield takeLatest(types.FETCH_BANK_ACCOUNT_DETAILS_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: fetchBankAccountDetailsFlow,
      apiService: UserService.fetchBankAccountDetails,
    })
  );
}

export function* setDefaultBankAccount() {
  yield takeLatest(types.SET_DEFAULT_BANK_ACCOUNT_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: setDefaultBankAccountFlow,
      apiService: UserService.updateDefaultBankAccount,
    })
  );
}

export function* removeBankAccount() {
  yield takeLatest(types.REMOVE_BANK_ACCOUNT_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: removeBankAccountFlow,
      apiService: UserService.removeBankAccount,
    })
  );
}

export function* addBankAccount() {
  yield takeLatest(types.ADD_BANK_ACCOUNT_REQUEST, action =>
    doSagaActionGenerator({
      action,
      flow: addBankAccountFlow,
      apiService: UserService.addBankAccount,
    })
  );
}
