import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const MerchantsService = (merchantId, storeId) =>
  createService(Apis.merchants, {
    getStores: {
      method: 'GET',
      url: `${Apis.merchants}/${merchantId}/stores`,
    },
    getStore: {
      method: 'GET',
      url: `${Apis.merchants}/${merchantId}/stores/${storeId}`,
    },
    getStoreProducts: {
      method: 'GET',
      url: `${Apis.merchants}/${merchantId}/stores/${storeId}/storeProducts`,
    },
  });

export default MerchantsService;
