import Apis from 'apis';
import { createService } from 'utils/serviceFactory';

const UserService = createService(Apis.users, {
  fetchBankAccounts: {
    method: 'GET',
    url: `${Apis.users}/me/items`,
  },
  fetchBankAccountDetails: {
    method: 'GET',
    url: `${Apis.users}/me/items/:itemID`,
  },
  // updateDefaultBankAccount: {
  //   method: 'PUT',
  //   url: `${Apis.users}/me/items/:itemID/account/default`,
  // },
  updateDefaultBankAccount: {
    method: 'PUT',
    url: `${Apis.users}/me`,
  },
  removeBankAccount: {
    method: 'DELETE',
    url: `${Apis.users}/me/items/:itemID`,
  },
  addBankAccount: {
    method: 'POST',
    url: `${Apis.users}/me/items/add`,
  },
  createPasses: {
    method: 'POST',
    url: `${Apis.users}/me/passes`,
  },
});

export default UserService;
