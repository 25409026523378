import { createSelector } from 'reselect';

export const rewardsSelector = () =>
  createSelector(
    state => state.reward,
    reward => reward.rewards
  );
export const rewardSourcesSelector = () =>
  createSelector(
    state => state.reward,
    reward => reward.sources
  );
