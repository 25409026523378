import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCoupon = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 16px 10px;
  position: relative;
  font-size: 11px;

  h4 {
    font-size: 15px;
    font-weight: 600;
    min-height: 48px;
    margin-bottom: 12px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #f3f5f9;
    border-radius: 50%;
  }
  &::before {
    left: 0;
    transform: translateX(-50%);
  }
  &::after {
    right: 0;
    transform: translateX(50%);
  }
`;

const Coupon = ({ title, content }) => {
  return (
    <StyledCoupon>
      <h4>{title}</h4>
      <div>{content}</div>
    </StyledCoupon>
  );
};

Coupon.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
};

Coupon.defaultProps = {
  content: null,
};

export default Coupon;
