export const ADD_BANK_ACCOUNT_REQUEST = 'ADD_BANK_ACCOUNT_REQUEST';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILURE = 'ADD_BANK_ACCOUNT_FAILURE';

export const FETCH_BANK_ACCOUNTS_REQUEST = 'FETCH_BANK_ACCOUNTS_REQUEST';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE';

export const FETCH_BANK_ACCOUNT_DETAILS_REQUEST =
  'FETCH_BANK_ACCOUNT_DETAILS_REQUEST';
export const FETCH_BANK_ACCOUNT_DETAILS_SUCCESS =
  'FETCH_BANK_ACCOUNT_DETAILS_SUCCESS';
export const FETCH_BANK_ACCOUNT_DETAILS_FAILURE =
  'FETCH_BANK_ACCOUNT_DETAILS_FAILURE';

export const SET_DEFAULT_BANK_ACCOUNT_REQUEST =
  'SET_DEFAULT_BANK_ACCOUNT_REQUEST';
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  'SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  'SET_DEFAULT_BANK_ACCOUNT_FAILURE';

export const REMOVE_BANK_ACCOUNT_REQUEST = 'REMOVE_BANK_ACCOUNT_REQUEST';
export const REMOVE_BANK_ACCOUNT_SUCCESS = 'REMOVE_BANK_ACCOUNT_SUCCESS';
export const REMOVE_BANK_ACCOUNT_FAILURE = 'REMOVE_BANK_ACCOUNT_FAILURE';
