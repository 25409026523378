import { useState, useCallback, useEffect } from 'react';

const geoCodingRes = {
  geometry: {
    location: {
      lat: null,
      lng: null,
    },
    location_type: '',
  },
};

const useGeocoding = (address = 'Chicago', immediate = true) => {
  const [geoCoder, setGeocoder] = useState(geoCodingRes);
  const [isRetrieving, setRetrieving] = useState(true);

  const getGeoCoder = useCallback(() => {
    if (!window.google) return;
    setRetrieving(true);
    new window.google.maps.Geocoder().geocode({ address }, response => {
      try {
        const [data] = response;
        const lat = data.geometry.location.lat();
        const lng = data.geometry.location.lng();

        setGeocoder({
          geometry: {
            ...data,
            location: {
              lat,
              lng,
            },
          },
        });
      } catch {}
    });
  }, [address]);

  useEffect(() => {
    if (immediate) {
      getGeoCoder();
    }
  }, [immediate, address]);

  return {
    getGeoCoder,
    isRetrieving,
    geoCoder,
  };
};

export default useGeocoding;
