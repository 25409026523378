import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Card } from 'components/common';
import { rewardsSelector } from 'redux/Reward/Reward.selectors';
import Rewards from 'components/Rewards/Rewards';
import ProgressStepBar from 'components/common/ProgressStepBar';
import ProgressPoints from 'components/common/ProgressPoints';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  padding: 30px;
`;

export default function RewardsTab() {
  const rewards = useSelector(rewardsSelector());
  return (
    <Container>
      <div className="text-center mb-3">
        <img
          src="/assets/images/bg-reward.svg"
          className="w-full"
          alt="reward"
        />
        <StyledCard white className="mt-3 mb-3">
          <ProgressStepBar />
          <div className="mt-6">
            <ProgressPoints />
          </div>
        </StyledCard>
      </div>
      <section id="reward">
        <div className="flex justify-content-space-between align-items-center mb-5">
          <h6>SPENCE REWARDS</h6>
          <Button size="small" secondary>
            View Map
          </Button>
        </div>
        <Rewards rewards={rewards} />
        <Button block>Load More</Button>
      </section>
    </Container>
  );
}
