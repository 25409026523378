import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const initialState = {};
  const middleware = [sagaMiddleware, logger];

  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  );
  sagaMiddleware.run(rootSaga);
  window.store = store;
  return store;
};

export default configureStore;
