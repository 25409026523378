import { createSelector } from 'reselect';

const loginSelector = () => state => state.auth.login;
export const loginActiveStepSelector = () =>
  createSelector(loginSelector(), login => login.activeStep);
export const loginDetailsSelector = () =>
  createSelector(loginSelector(), login => login.details);

const authSelector = () => state => state.auth;
export const userDetailsSelector = () =>
  createSelector(authSelector(), auth => auth.userDetails);
