// ACTION TYPES
import * as types from './Register.actionTypes';

export const updateRegisterDetails = params => ({
  type: types.UPDATE_REGISTER_FORM_DETAILS,
  payload: {
    data: params,
  },
});

export const updateRegisterPinCode = params => ({
  type: types.UPDATE_REGISTER_FORM_DETAILS,
  payload: {
    data: params,
  },
});

export const changeRegisterStep = ({ stepIndex, finishedStep }) => ({
  type: types.UPDATE_REGISTER_STEP,
  payload: {
    stepIndex,
    finishedStep,
  },
});

export const createUserDetailsFlow = {
  request: ({ params, callback }) => ({
    type: types.CREATE_USER_DETAILS_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.CREATE_USER_DETAILS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.CREATE_USER_DETAILS_FAILURE,
    payload: err,
  }),
};
export const createUserDetails = createUserDetailsFlow.request;

export const fetchRegisteringUserDetailsFlow = {
  request: params => ({
    type: types.FETCH_REGISTERING_USER_DETAILS_REQUEST,
    params,
  }),
  success: data => ({
    type: types.FETCH_REGISTERING_USER_DETAILS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_REGISTERING_USER_DETAILS_FAILURE,
    payload: err,
  }),
};
export const fetchRegisteringUserDetails =
  fetchRegisteringUserDetailsFlow.request;

export const verifyPhoneNumberFlow = {
  request: ({ params, callback }) => ({
    type: types.VERIFY_PHONE_NUMBER_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.VERIFY_PHONE_NUMBER_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.VERIFY_PHONE_NUMBER_FAILURE,
    payload: err,
  }),
};
export const verifyPhoneNumber = verifyPhoneNumberFlow.request;

export const sendVerifyCodeFlow = {
  request: ({ params, callback }) => ({
    type: types.SEND_VERIFY_CODE_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.SEND_VERIFY_CODE_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.SEND_VERIFY_CODE_FAILURE,
    payload: err,
  }),
};
export const sendVerifyCode = sendVerifyCodeFlow.request;

export const setPinCodeFlow = {
  request: ({ params, callback }) => ({
    type: types.SET_PIN_CODE_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.SET_PIN_CODE_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.SET_PIN_CODE_FAILURE,
    payload: err,
  }),
};
export const setPinCode = setPinCodeFlow.request;

export const linkBankAccountFlow = {
  request: ({ params, callback }) => ({
    type: types.LINK_BANK_ACCOUNT_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.LINK_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.LINK_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const linkBankAccount = linkBankAccountFlow.request;
