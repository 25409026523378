// HELPER
import { setToken } from 'utils/tokenHelper';

// ACTION TYPES
import * as types from './Register.actionTypes';

// INITIAL STATE
import { initialState } from './Register.initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_REGISTER_FORM_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload.data,
        },
      };
    }

    case types.UPDATE_REGISTER_STEP: {
      return {
        ...state,
        activeStep: action.payload.stepIndex,
        finishedStep:
          action.payload.finishedStep ||
          Math.max(state.finishedStep, action.payload.stepIndex),
      };
    }

    case types.CREATE_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          userID: action.payload.userID,
          status: action.payload.status,
        },
      };
    }

    case types.VERIFY_PHONE_NUMBER_SUCCESS:
    case types.SEND_VERIFY_CODE_SUCCESS:
    case types.LINK_BANK_ACCOUNT_SUCCESS: {
      const { accessToken } = action.payload;
      setToken(accessToken);
      return {
        ...state,
        details: {
          ...state.details,
        },
      };
    }

    case types.LINK_BANK_ACCOUNT_SUCCESS: {
      const { accessToken, item } = action.payload;
      setToken(accessToken);
      return {
        ...state,
        details: {
          ...state.details,
          bank: item,
        },
      };
    }

    case types.SET_PIN_CODE_SUCCESS: {
      const { accessToken } = action.payload;
      if (accessToken) {
        setToken(accessToken);
      }
      return {
        ...state,
        details: {
          ...state.details,
          pinCodeIsSet: true,
        },
      };
    }

    default:
      return { ...state };
  }
};
