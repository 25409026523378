import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

const StyledWrapper = styled.div`
  display: flex;
  box-shadow: 0px 12px 19px rgba(108, 108, 108, 0.1);
  border-radius: 16px;
  background-color: #fff;
`;

const StyledButton = styled.div`
  flex: 1;
  padding: 13px;
  text-align: center;
  border-right: 1px solid #f3f5f9;
  transition: 0.4s all;
  &.selected {
    margin: -1px;
    padding: 14px;
    box-shadow: 0 0 0 2px inset ${props => props.theme.colorPrimary} !important;
  }

  &:first-child {
    border-radius: 16px 0 0 16px;
  }

  &:last-child {
    border: 0;
    border-radius: 0 16px 16px 0;
  }
`;

const ToggleGroup = ({ onChange, items, selectedItem, className }) => {
  if (!Array.isArray(items) || !items.length) return null;
  return (
    <StyledWrapper>
      {items.map(item => (
        <StyledButton
          onClick={() => onChange(item)}
          key={item.key}
          className={classnames(className, {
            selected: selectedItem && selectedItem.key === item.key,
          })}
        >
          {item.content}
        </StyledButton>
      ))}
    </StyledWrapper>
  );
};

ToggleGroup.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
ToggleGroup.defaultProps = {
  items: [],
  selectedItem: null,
  className: '',
  onChange: null,
};

export default ToggleGroup;
