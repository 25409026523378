import React from 'react';
import { Container, Button } from 'components/common';
import { Link } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${props => props.theme.colorText};
  font-size: 15px;
`;

const StyledHeadline = styled.h6`
  font-size: 17px;
  line-height: 26px;
  opacity: 0.8;
`;

const OrangeButton = styled(Button)`
  background-color: rgba(255, 187, 16, 0.1);
  border: none;
  outline: none;
  color: ${props => props.theme.colorOrange};
`;

const StyledContent = styled.table`
  font-size: 15px;

  td.content {
    padding: 0 20px;
  }
`;

export default function RewardDetail() {
  return (
    <Container>
      <StyledLink
        to="/merchants/stores/33"
        className="text-black flex align-items-center"
      >
        <FiChevronLeft className="mr-1" />
        <strong>Back</strong>
      </StyledLink>
      <StyledHeadline className="pt-4 pb-4">
        Discount 20% on total bill
      </StyledHeadline>
      <StyledContent>
        <tbody>
          <tr>
            <td>Points</td>
            <td className="content">
              <div>
                <OrangeButton size="small" secondary>
                  <div className="flex">
                    <img
                      src="/assets/images/icons/points-orange.svg"
                      alt="point"
                    />
                    <span className="ml-2">1245</span>
                  </div>
                </OrangeButton>
              </div>
            </td>
          </tr>
          <tr>
            <td>Vaild Date</td>
            <td className="content">12 Dec 2019 to 30 Mar 2020</td>
          </tr>
        </tbody>
      </StyledContent>
      <StyledHeadline className="pt-4">Term & Conditions</StyledHeadline>
      <p style={{ fontSize: '15px' }}>
        Google LLC is an American multinational technology company that
        specializes in Internet-related services.. Read More
      </p>
      <Button primary block>
        Redeem
      </Button>
    </Container>
  );
}
