import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// COMPONENTS
import { Container, Button, Tabs } from 'components/common';
import Map from 'components/Map/Map';
import StoreOpenHour from 'components/Merchants/OpenHour';

import { useSelector } from 'react-redux';
import { storeDetailSelector } from 'redux/Stores/Stores.selectors';
import StoreDeals from 'components/Merchants/StoreDeals';

const StyledHeadline = styled.h3`
  font-size: 17px;
  color: ${props => props.theme.colorText};
  line-height: 26px;
  margin-bottom: 8px;
`;

const StyledMap = styled.div`
  position: relative;
  width: 100%;
  height: 125px;
  box-shadow: ${props => props.theme.inputBoxShadow};
  border-radius: ${props => props.theme.cardBorderRadius};
  overflow: hidden;

  #map-info {
    position: absolute;
    border-bottom-right-radius: ${props => props.theme.cardBorderRadius};
    border-bottom-left-radius: ${props => props.theme.cardBorderRadius};
    bottom: 0;
    width: 100%;
    height: 40px;
    color: #fff;
    font-weight: bold;
    z-index: 10;

    &::before {
      content: '';
      border-bottom-right-radius: ${props => props.theme.cardBorderRadius};
      border-bottom-left-radius: ${props => props.theme.cardBorderRadius};
      background-color: #000;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & > div {
      height: 100%;
      position: relative;
      z-index: 2;
    }
  }
`;

function WebsiteLabel() {
  const { store } = useSelector(storeDetailSelector());
  return (
    <a href={store.website} className="flex flex--column align-items-center">
      <img
        className="mb-2"
        src="/assets/images/icons/website.svg"
        alt="website"
      />
      <span>Website</span>
    </a>
  );
}

function PhoneLabel() {
  const { store } = useSelector(storeDetailSelector());
  return (
    <a
      href={`tel:+${store.phoneNumber}`}
      className="flex flex--column align-items-center"
    >
      <img
        className="mb-2"
        src="/assets/images/icons/cellphone.svg"
        alt="website"
      />
      <span>Call</span>
    </a>
  );
}

const tabs = [
  {
    key: 'website',
    label: <WebsiteLabel />,
  },
  {
    key: 'phone',
    label: <PhoneLabel />,
  },
];

function DetailTab() {
  const { store, openDays } = useSelector(storeDetailSelector());
  return (
    <Container>
      <div className="pt-2 pb-5">
        <Button as={Link} primary block to="/merchants/favorites">
          Add Store To Favorites
        </Button>
      </div>
      <StoreDeals />
      <div id="about" className="mb-6">
        <StyledHeadline>About {store.name}</StyledHeadline>
        <p style={{ fontSize: '15px' }} className="mt-0 mb-6 text--grey">
          {store.description}
        </p>
        <hr className="mt-3 mb-3" />
        <Tabs defaultActive="-1" tabs={tabs} />
      </div>
      <section id="map">
        <StyledMap className="mb-5">
          <Map initialCenter={{ lat: store.lat, lng: store.lng }} />
          <a href="#" id="map-info">
            <div className="flex justify-content-center align-items-center">
              <img src="/assets/images/icons/place.svg" alt="map" />
              <span className="ml-3">Directions</span>
            </div>
          </a>
        </StyledMap>
      </section>
      <StoreOpenHour openDays={store.openDays} />
    </Container>
  );
}
export default DetailTab;
