import { BrowserRouter as Router, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';

// Import CSS reset and Global Styles
import GlobalStyle from 'styles/globals';

// COMPONENTS
import NotificationsProvider from 'components/Notifications/NotificationsProvider';
import AuthenticatedRoute from 'components/AuthenticatedRoute/AuthenticatedRoute';
import AnimatedSwitch from 'components/AnimatedSwitch/AnimatedSwitch';

// CONSTANTS
import Loading from 'components/Loading/Loading';
import { THEME } from './constants/theme';
import PageMerchants from './pages/PageMerchants/PageMerchants';

const Home = lazy(() => import('./pages/Home.jsx'));
const About = lazy(() => import('./pages/About.jsx'));
const PageLogin = lazy(() => import('./pages/PageLogin/PageLogin'));
const PageRegister = lazy(() => import('./pages/PageRegister/PageRegister'));
const PageDashboard = lazy(() => import('./pages/PageDashboard/PageDashboard'));
const PageTransactionHistory = lazy(() =>
  import('./pages/PageTransactionHistory/PageTransactionHistory')
);
const PageCardsManagement = lazy(() =>
  import('./pages/PageCardsManagement/PageCardsManagement')
);
const PageCardDetails = lazy(() =>
  import('./pages/PageCardDetails/PageCardDetails')
);
const PageCreateNewCard = lazy(() =>
  import('./pages/PageCreateNewCard/PageCreateNewCard')
);

const PageQrCode = lazy(() => import('./pages/PageQrCode/PageQrCode'));
const PageRewards = lazy(() => import('./pages/PageRewards/PageRewards'));
const PageFavorites = lazy(() => import('./pages/PageFavorites/PageFavorites'));
const PageQrModal = lazy(() => import('./pages/PageQrModal/PageQrModal'));
const PageFavoriteStores = lazy(() =>
  import('./pages/PageFavorites/modules/FavoriteStores')
);
const PagePay = lazy(() => import('./pages/PagePay/PagePay'));

const PageVerifyID = lazy(() => import('./pages/PageVerifyID/PageVerifyID'));

const App = () => (
  <ThemeProvider theme={THEME}>
    <GlobalStyle />
    <NotificationsProvider>
      <Router>
        <Suspense fallback={<Loading type="dots" isScreen />}>
          <AnimatedSwitch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={PageLogin} />

            {/* REGISTER */}
            <Route exact path="/register" component={PageRegister} />
            <Route exact path="/register/phone" component={PageRegister} />
            <Route exact path="/register/verify" component={PageRegister} />
            <Route exact path="/register/pin-code" component={PageRegister} />
            <Route
              exact
              path="/register/link-bank-account"
              component={PageRegister}
            />

            <Route path="/about" component={About} />
            <Route path="/pay" component={PagePay} />

            <Route path="/rewards" component={PageRewards} />
            <Route exact path="/favorites" component={PageFavorites} />
            <Route
              exact
              path="/favorites/stores"
              component={PageFavoriteStores}
            />

            <Route path="/verify-id" component={PageVerifyID} />

            <AuthenticatedRoute>
              {({ userDetail }) => (
                <>
                  <Route exact path="/dashboard" component={PageDashboard} />
                  <Route
                    exact
                    path="/transactions"
                    component={PageTransactionHistory}
                  />
                  <Route exact path="/cards" component={PageCardsManagement} />
                  <Route
                    exact
                    path="/my-qrcode"
                    component={props => (
                      <PageQrCode userDetail={userDetail} {...props} />
                    )}
                  />
                  <Route exact path="/qr-modal" component={PageQrModal} />
                  <AnimatedSwitch>
                    <Route
                      exact
                      path="/cards/new"
                      component={PageCreateNewCard}
                    />
                    <Route
                      exact
                      path="/cards/:cardId"
                      component={PageCardDetails}
                    />
                  </AnimatedSwitch>
                  <Route path="/merchants" component={PageMerchants} />
                </>
              )}
            </AuthenticatedRoute>
          </AnimatedSwitch>
        </Suspense>
      </Router>
    </NotificationsProvider>
  </ThemeProvider>
);

export default App;
