import * as types from './BankAccount.actionTypes';

export const fetchBankAccountsFlow = {
  request: () => ({
    type: types.FETCH_BANK_ACCOUNTS_REQUEST,
  }),
  success: data => ({
    type: types.FETCH_BANK_ACCOUNTS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_BANK_ACCOUNTS_FAILURE,
    payload: err,
  }),
};
export const fetchBankAccounts = fetchBankAccountsFlow.request;

export const fetchBankAccountDetailsFlow = {
  request: ({ queryParams }) => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_REQUEST,
    queryParams,
  }),
  success: data => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.FETCH_BANK_ACCOUNT_DETAILS_FAILURE,
    payload: err,
  }),
};
export const fetchBankAccountDetails = fetchBankAccountDetailsFlow.request;

export const setDefaultBankAccountFlow = {
  request: ({ queryParams, params }) => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_REQUEST,
    queryParams,
    params,
  }),
  success: data => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.SET_DEFAULT_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const setDefaultBankAccount = setDefaultBankAccountFlow.request;

export const removeBankAccountFlow = {
  request: ({ queryParams, callback }) => ({
    type: types.REMOVE_BANK_ACCOUNT_REQUEST,
    queryParams,
    callback,
  }),
  success: data => ({
    type: types.REMOVE_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.REMOVE_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const removeBankAccount = removeBankAccountFlow.request;

export const addBankAccountFlow = {
  request: ({ params, callback }) => ({
    type: types.ADD_BANK_ACCOUNT_REQUEST,
    params,
    callback,
  }),
  success: data => ({
    type: types.ADD_BANK_ACCOUNT_SUCCESS,
    payload: data,
  }),
  failure: err => ({
    type: types.ADD_BANK_ACCOUNT_FAILURE,
    payload: err,
  }),
};
export const addBankAccount = addBankAccountFlow.request;
