import React from 'react';
import { Container } from 'components/common';

export default function LocationsTab() {
  return (
    <Container>
      <h6>Store location</h6>
    </Container>
  );
}
