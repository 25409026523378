import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';

// ACTIONS
import { removeNotification } from 'redux/GlobalUI/GlobalUI.actions';

// HOOKS
import { useInterval } from 'utils/hooks';

// ICONS
import { FiCheck, FiX } from 'react-icons/fi';

const StyledNotification = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 22px;
  margin-bottom: 16px;
  background-color: #ffff;
  box-shadow: 0px 10px 25px rgba(164, 164, 164, 0.3);
  border-radius: 16px;
  z-index: 9999;
  animation: notificationHiding 0.4s ease-in-out forwards;

  &.active {
    animation: notificationShowing 0.4s ease-in-out forwards;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .icon {
    color: #fff;
    border-radius: 50%;
    flex-grow: 2;
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    margin-right: 16px;
    &--success {
      background-color: ${props => props.theme.colorPrimary};
      box-shadow: 0px 5px 12.5px rgba(0, 207, 120, 0.3);
    }
    &--error {
      background-color: ${props => props.theme.colorDanger};
    }
  }
  .message {
    letter-spacing: -0.24px;
    font-size: 15px;
    font-weight: 500;
    flex: 1;
  }
  .close-btn {
    margin-left: 22px;
  }

  @keyframes notificationShowing {
    0% {
      transform: translateY(-20px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes notificationHiding {
    0% {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
    100% {
      transform: translateY(-20px);
      opacity: 0;
      visibility: hidden;
    }
  }
`;

const Notification = ({ type, message, notificationIndex }) => {
  const [currentCount, setCurrentCount] = useState(8);
  const [removing, setRemoving] = useState(false);
  const dispatch = useDispatch();

  useInterval(() => {
    if (currentCount > 0) {
      setCurrentCount(currentCount - 1);
      if (currentCount < 3) {
        setRemoving(true);
      }
    } else {
      // Delete notification
      dispatch(removeNotification({ notificationIndex }));
    }
  }, 500);

  const onClose = () => {
    setRemoving(true);
    setTimeout(() => {
      dispatch(removeNotification({ notificationIndex }));
    }, 1500);
  };

  return (
    <StyledNotification
      className={classnames('notification', { active: !removing })}
    >
      <span
        className={classnames(
          'icon flex align-items-center justify-content-center',
          {
            'icon--success': type === 'success',
            'icon--error': type === 'error',
          }
        )}
      >
        {type === 'success' && <FiCheck />}
        {type === 'error' && <FiX />}
      </span>
      <span className="message">{message}</span>
      <span className="close-btn" onClick={onClose}>
        <FiX />
      </span>
    </StyledNotification>
  );
};

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  notificationIndex: PropTypes.number,
};
Notification.defaultProps = {
  type: null,
  message: null,
  notificationIndex: null,
};
export default Notification;
