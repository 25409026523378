import React from 'react';
import PropTypes from 'prop-types';

import Reward from './Reward';

const Rewards = ({ rewards }) => {
  return (
    <div className="flex flex--wrap justify-content-space-between">
      {rewards.map(item => (
        <Reward key={item.id} reward={item} />
      ))}
    </div>
  );
};

Rewards.propTypes = {
  rewards: PropTypes.array,
};
Rewards.defaultProps = {
  rewards: [],
};
export default Rewards;
