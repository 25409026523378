import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import StoreExplore from './modules/StoreExplore/StoreExplore';
import StoreDetail from './modules/StoreDetail/StoreDetail';
import RewardDetail from './modules/RewardDetails/RewardDetails';

function PageMerchant() {
  return (
    <Switch>
      <Route exact path="/merchants/stores" component={StoreExplore} />
      <Route path="/merchants/:merchantId/stores/:id" component={StoreDetail} />
      <Route
        exact
        path="/merchants/:merchantId/stores/:id/reward/:id"
        component={RewardDetail}
      />
      <Redirect from="*" to="/merchants/stores" />
    </Switch>
  );
}

export default PageMerchant;
